import React from "react";
import { Button } from "react-md";
import ForTeachersLaptop from "../../assets/images/laptop.png";
import BuiltByTeachers from "../../assets/images/callout.png";
import { navigate } from "gatsby";

function HalfImage() {
  return (
    <section>
      <div className=" index__halfImage1">
        <div className="index__halfImage1-wrapper">
          <div className="index__halfImage1-info">
            <h2 className="index__halfImage1-info-header">For Teachers</h2>
            <p className="index__halfImage1-info-description">
              Use DeltaMath's modules to create high-leverage assignments and
              track student learning. With DeltaMath PLUS or INTEGRAL, students also get
              access to help videos. Create and assign tests, assign specific
              problem-types, even create your own problem.
            </p>
            <Button
              onClick={() => {
                if (typeof window !== "undefined")
                  navigate("/teachers-schools");
              }}
              className="index__halfImage1-info-button"
              theme="primary"
              themeType="contained"
            >
              Learn More
            </Button>
          </div>

          <div className="index__halfImage1-image">
            <img
              className="index__halfImage1-image-laptop"
              src={ForTeachersLaptop}
              alt="For parents laptop"
            />
            <img
              className="index__halfImage1-image-teachers"
              src={BuiltByTeachers}
              alt="Built by teachers"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HalfImage;
