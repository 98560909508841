import React from "react";

import Slider from "react-slick";

import AshleyBandyAvatar from "../../assets/images/testimonials/ashley bandy.png";
import BobsonWongAvatar from "../../assets/images/testimonials/bobson wong.png";
import DanilsaFernandezAvatar from "../../assets/images/testimonials/danilsa fernandez.png";
import GabbyJuanAvatar from "../../assets/images/testimonials/Gabby Juan.png";
import GlorybethBeckerAvatar from "../../assets/images/testimonials/glorybeth becker.png";
import HolliPaverAvatar from "../../assets/images/testimonials/holli paver.png";
import IanOlanoAvatar from "../../assets/images/testimonials/ian olano.png";
import JeanAristildeAvatar from "../../assets/images/testimonials/Jean Aristilde.png";
import JeffHamiltonAvatar from "../../assets/images/testimonials/jeff hamilton.png";
import JeffreyWojcikAvatar from "../../assets/images/testimonials/jeffrey wojcik.png";
import JosiahShoemakerAvatar from "../../assets/images/testimonials/josiah shoemaker.png";
import KarlaSummersAvatar from "../../assets/images/testimonials/karla summers.png";
import LauraKinnelAvatar from "../../assets/images/testimonials/laura kinnel.png";
import MariaNunkesterAvatar from "../../assets/images/testimonials/maria nunkester.png";
import MariamTanAvatar from "../../assets/images/testimonials/mariam tan.png";
import MarissaProctorAvatar from "../../assets/images/testimonials/marissa proctor.png";
import MeganAlvarezAvatar from "../../assets/images/testimonials/megan alvarez.png";
import NatalieHurleyAvatar from "../../assets/images/testimonials/natalie hurley.png";
import RebeccaBalakovitsAvatar from "../../assets/images/testimonials/rebecca balakovits.png";
import RiaDaveyAvatar from "../../assets/images/testimonials/ria davey.png";
import TyquanWardAvatar from "../../assets/images/testimonials/tyquan ward.png";
import VictorAriasAvatar from "../../assets/images/testimonials/victor arias.png";
import ZachOvercashAvatar from "../../assets/images/testimonials/zach overcash.png";

import CarousalCard from "./CarousalCard";
import "../../styles/components/Home/homeCarousel.scss";

// CUSTOM ARROWS FOR REACT SLICK CAROUSAL
const RightArrow = ({ className, style, onClick }) => (
  <div
    className="right-arrow"
    style={{
      ...style,
      width: "30px",
      height: "30px",
      boxShadow: "-2px 2px 0 #6b6b6b",
      opacity: "0.5",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%) rotate(-135deg)",
      right: "-25px",
      cursor: "pointer",
    }}
    onClick={onClick}
  ></div>
);

const LeftArrow = ({ className, style, onClick }) => (
  <div
    className="left-arrow"
    style={{
      ...style,
      width: "30px",
      height: "30px",
      boxShadow: "-2px 2px 0 #6b6b6b",
      opacity: "0.5",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%) rotate(45deg)",
      left: "-25px",
      cursor: "pointer",
    }}
    onClick={onClick}
  ></div>
);

//  SETTINGS FOR REACT SLICK CAROUSAL
var settings = {
  dots: false,
  infinite: true,
  lazyLoad: "ondemand",
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerPadding: "20px",
  centerMode: false,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        lazyLoad: "progressive",
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
// test change
let carouselData = [
  {
    name: "Ashley Bandy",
    role: "Newton, NC",
    text: "Since it's designed by math teachers, it does everything math teachers want. I love that the assignments are customizable. It makes scaffolding easy. My students love the built-in help and immediate feedback!",
    avatar: `${AshleyBandyAvatar}`,
  },
  {
    name: "Bobson Wong",
    role: `Co-author, <i><a href="https://www.amazon.com/Math-Teachers-Toolbox-Hundreds-Practical/dp/1119573297/">The Math Teacher's Toolbox</a></i>`,
    text: "I love the ability to assign both  self-graded problems and my own custom-made questions. By analyzing student performance online, I can tailor instruction to meet their needs.",
    avatar: `${BobsonWongAvatar}`,
    style: "no-bottom-padding",
  },
  {
    name: "Danilsa Fernandez",
    role: "New York, NY",
    text: "DeltaMath is a terrific supplement to my math instruction. I especially enjoy assigning the guided questions because they provide the scaffolding students need to become confident math learners.",
    avatar: `${DanilsaFernandezAvatar}`,
  },
  {
    name: "Gabby Juan",
    role: "Palo Alto, CA",
    text: "From NYC to CA, my students and colleagues love DeltaMath! I like the breakdown of specific skills based on course level, and my students find the example videos and step-by-step solutions extremely helpful!",
    avatar: `${GabbyJuanAvatar}`,
  },
  {
    name: "Glorybeth Becker",
    role: "Cary, NC",
    text: "My students ASK for DeltaMath problems for practice. The problems are well thought out and the solutions are amazingly well-written. So thank you for making math fun to teach and equally fun to learn!",
    avatar: `${GlorybethBeckerAvatar}`,
  },
  {
    name: "Holli Paver",
    role: "Urbandale, IA",
    text: "DeltaMath has been a game-changer for homework in my class. I appreciate its user-friendliness and the new DeltaMath PLUS features that allow grading to be so simple. And best of all, the students love it!",
    avatar: `${HolliPaverAvatar}`,
  },
  {
    name: "Ian Olano",
    role: "Taylor Mill, KY",
    text: "Transforms homework into powerful, mastery-based formative assessment modules with immediate student feedback and assistance. The live data component keeps teachers up-to-the-minute on student progress.",
    avatar: `${IanOlanoAvatar}`,
  },
  {
    name: "Jean Aristilde",
    role: "Brooklyn, NY",
    text: "I have been using DeltaMath for five years and it's been a lifesaver. I have PLUS which allows me to give tests remotely where students do not have to get the same questions. Simply the best online program for learning math.",
    avatar: `${JeanAristildeAvatar}`,
  },
  {
    name: "Jeff Hamilton",
    role: "New York, NY",
    text: "Anything we do in class can be made into an assignment using the “Create Your Own Problem” feature! I enjoy creating “guided sentence” questions to support special education and emerging bilingual students.",
    avatar: `${JeffHamiltonAvatar}`,
  },
  {
    name: "Jeffrey Wojcik",
    role: "Port Washington, WI",
    text: "DeltaMath is the best way for students to practice mathematics. The site has so many elements in place to support student learning. The step-by-step explanations and help videos are great for helping students help themselves.",
    avatar: `${JeffreyWojcikAvatar}`,
  },
  {
    name: "Josiah Shoemaker",
    role: "West Chester, PA",
    text: "DeltaMath has been a game changer for myself and my students. My students get extra practice when they need it, and the help videos give another way of explaining a concept. I'm never going back to a textbook!",
    avatar: `${JosiahShoemakerAvatar}`,
  },
  {
    name: "Karla Summers",
    role: "Columbus, GA",
    text: "DeltaMath gives me the ability to connect with ALL my students. I can monitor progress in real time, assign videos, and modify assignments or assessments to accommodate students with special needs.",
    avatar: `${KarlaSummersAvatar}`,
  },
  {
    name: "Laura Kinnel",
    role: "Newtown, PA",
    text: "This is so much better than the old odd-answers-in-the-back-of-the-book method. The variety of problems and the way so many of them encourage thinking about core concepts is great.",
    avatar: `${LauraKinnelAvatar}`,
  },
  {
    name: "Maria Nunkester",
    role: "Catawissa, PA",
    text: "I've been using DeltaMath since Jan 2018 and CANNOT say enough! My students LOVE it. Great for classroom use, test prep, SAT/ACT skills refreshers, even a summer College Algebra course. Highly recommend!",
    avatar: `${MariaNunkesterAvatar}`,
  },
  {
    name: "Mariam Tan",
    role: "Fullerton, CA",
    text: "As a math department chair and proponent of REAL LEARNING, I advocated for all of my math teachers to get PLUS. Now I cannot imagine being without it. The questions and explanations are FIRE (as the kids would say)!",
    avatar: `${MariamTanAvatar}`,
  },
  {
    name: "Marissa Proctor",
    role: "Yorktown, NY",
    text: "The beauty of using DeltaMath is your ability to meet each student where they’re at while also holding all students accountable in the digital classroom.",
    avatar: `${MarissaProctorAvatar}`,
  },
  {
    name: "Megan Alvarez",
    role: "Clarksville, TN",
    text: "DeltaMath allows me to offer rigorous yet accessible standards-aligned material. I love the immediate feedback it offers my students as well as the additional supports to further learning.",
    avatar: `${MeganAlvarezAvatar}`,
  },
  {
    name: "Natalie Hurley",
    role: "Watertown, NY",
    text: "As a teacher of Algebra, Precalculus, and Calculus, I assign DeltaMath to my students to build fluency and mastery. Its functionality proves DeltaMath is designed for teachers and 21st-century learning.",
    avatar: `${NatalieHurleyAvatar}`,
  },
  {
    name: "Rebecca Balaskovits",
    role: "Leonia, NJ",
    text: "DeltaMath has forever changed the way I differentiate and instruct! You can definitely tell that this website was made BY a math teacher FOR math teachers!",
    avatar: `${RebeccaBalakovitsAvatar}`,
  },
  {
    name: "Ria Davey",
    role: "Arlington, MA",
    text: "Who needs a textbook when you have DeltaMath? The immediate feedback for students and the flexibility on tries and penalties make for an exceptional resource.",
    avatar: `${RiaDaveyAvatar}`,
  },
  {
    name: "Tyquan Ward",
    role: "Brooklyn, NY",
    text: "The DeltaMath platform has been a lifesaver. It gives students a plethora of problems to broaden their knowledge and skills. Highly recommended for in-person and at-home instruction.",
    avatar: `${TyquanWardAvatar}`,
  },
  {
    name: "Victor Arias",
    role: "Pereira, Colombia",
    text: "I have been using DeltaMath for over 3 years and it has been a great tool. The platform has changed the way I teach and the way my students learn. I will never use a textbook again!",
    avatar: `${VictorAriasAvatar}`,
  },
  {
    name: "Zach Overcash",
    role: "China Grove, NC",
    text: "DeltaMath made a year in a hybrid setting feasible. Though I only had them two days a week, 71% of my students passed the state exam. DeltaMath played a role no doubt.",
    avatar: `${ZachOvercashAvatar}`,
  },
];

function HomeCarousel() {
  return (
    <div className="index__homeCarousal">
      <h3 className="index__homeCarousal-header">
        Why do millions of people choose to learn on DeltaMath?
      </h3>
      <Slider className="index__homeCarousal-carousal" {...settings}>
        {carouselData
          .sort(() => Math.random() - 0.5)
          .map((data, index) => {
            return (
              <div
                key={index + 1000}
                className="index__homeCarousal-carousal-cardContainer"
              >
                <CarousalCard cardInfo={data} />
              </div>
            );
          })}
      </Slider>
    </div>
  );
}

export default HomeCarousel;
