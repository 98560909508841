import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

function Features() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop1: file(
          relativePath: { eq: "images/Vector Smart Object-2.png" }
        ) {
          childImageSharp {
            fixed(height: 53, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop2: file(
          relativePath: { eq: "images/Vector Smart Object-1.png" }
        ) {
          childImageSharp {
            fixed(height: 90, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desktop3: file(relativePath: { eq: "images/Vector Smart Object.png" }) {
          childImageSharp {
            fixed(height: 90, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  const imageData1 = data.desktop1.childImageSharp.fixed;
  const imageData2 = data.desktop2.childImageSharp.fixed;
  const imageData3 = data.desktop3.childImageSharp.fixed;

  return (
    <div className="container index__features">
      <div className="index__features-card">
        <div className="index__features-card-image-container">
          <Img fixed={imageData1} alt="Unlimited practice" />
        </div>
        <h3 className="index__features-card-header">Unlimited practice</h3>
        <p className="index__features-card-description">
          Problems are randomized, allowing students multiple opportunities to get the practice and feedback they need on the road to mastery.
        </p>
      </div>
      <div className="index__features-card">
        <div className="index__features-card-image-container">
          <Img fixed={imageData2} alt="Instant Feedback" />
        </div>
        <h3 className="index__features-card-header">Instant feedback</h3>
        <p className="index__features-card-description">
          Go beyond right and wrong. As soon as a student submits an answer, they get a detailed age-appropriate explanation.
        </p>
      </div>
      <div className="index__features-card">
        <div className="index__features-card-image-container">
          <Img fixed={imageData3} alt="Fine-tune controls" />
        </div>
        <h3 className="index__features-card-header">Fine-tune controls</h3>
        <p className="index__features-card-description">
          DeltaMath allows teachers to mix and match problem-sets, control rigor, vary due dates, and, with PLUS or INTEGRAL, create tests and problems of their own.
        </p>
      </div>
    </div>
  );
}

export default Features;
