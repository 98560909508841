import React from "react";
import { Avatar } from "@react-md/avatar";
import { Card, CardTitle, CardSubtitle, CardHeader } from "@react-md/card";
import { Typography } from "@react-md/typography";
import Quote from "../../assets/images/quote.png";

import "../../styles/components/Home/carouselCard.scss";

function CarousalCard(props) {
  return (
    <div className="carousalCard__wrapper">
      <Card className="carousalCard__card">
        {/* <CardContent> */}
        <div className="carousalCard__quote">
          {/* <FaQuoteLeft /> */}

          <img src={Quote} alt="Quote" />
        </div>
        <Typography className="carousalCard__text">{props.cardInfo.text}</Typography>
        {/* </CardContent> */}

        <Avatar src={props.cardInfo.avatar} />
        <CardHeader className={props.cardInfo.style}>
          <CardTitle className="carousalCard__name">
            {props.cardInfo.name}
          </CardTitle>
          <CardSubtitle
            className="carousalCard__role"
            dangerouslySetInnerHTML={{ __html: props.cardInfo.role }}
          ></CardSubtitle>
        </CardHeader>
      </Card>
    </div>
  );
}

export default CarousalCard;
