import React, { useEffect } from "react";
import fetch from "node-fetch";
import Flip from "./Flip";
import { getDeltaMathAPI } from "../../utilities/utilities";

function ProblemCounter() {
  const [problemCount, setProblemCount] = React.useState(0);
  const [problemRate, setProblemRate] = React.useState(0);
  const [fetchFailure, setFetchFailure] = React.useState(false);

  useEffect(() => {
    if (!fetchFailure && (problemCount === 0 || problemRate === 0)) {
      getProblemCount();
    }
  }, []);

  const getProblemCount = async () => {
    let res;
    let resText;
    let log_count;
    try {
      res = await fetch(getDeltaMathAPI() + '/log_count');
      resText = await res.text();
      log_count = JSON.parse(resText);
    } catch (err) {
      setFetchFailure(true);
    }
    if (log_count) {
      setProblemRate(log_count.rate);
      setProblemCount(log_count.count);
    }
  };

  React.useEffect(() => {
    if (!fetchFailure && problemCount) {
      const intervalId = setInterval(() => {
        setProblemCount(
          Math.round(
            problemCount + problemRate + Math.floor(9 * Math.random()) - 4
          )
        );
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [problemCount]);

  return (
    <div className="index__problemCounter">
      <div className="index__problemCounter-problemCount-flip">
        {/* <Flip value={problemCount} /> */}
        {problemCount
          .toLocaleString("en-US")
          .split("")
          .map((item, index) => {
            return item === "," ? (
              <div
                key={index + 2000}
                className="index__problemCounter-problemCount-comma"
              >
                {item}
              </div>
            ) : (
              <Flip key={index + 3000} value={parseInt(item)} />
            );
          })}
      </div>
      <div className="index__problemCounter-problemCount-small">
        {problemCount.toLocaleString("en-US")}
      </div>
      <div className="index__problemCounter-description">
        Problems solved with DeltaMath
      </div>
    </div>
  );
}

export default ProblemCounter;
